import { useNavigate, Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import logo from '../images/tattooswap_line.png';
import { PrimaryButton, SecondaryButton } from '../components/Buttons.jsx';
import { useState, useEffect } from 'react';
import UserAvatar from './UserAvatar.jsx';
import { selectPage } from '../redux/pageSlice.js';
import { setRequestsAmount } from '../redux/loginSlice.js';


const Navbar = () => {
    const dispatch = useDispatch();

    // Handle slected page icon
    const { selected } = useSelector(state => state.page)
    const handleSelectPage = (num) => {
        dispatch(selectPage(num));
    }

    //---- MANAGE LOGGED STATE -----//

    const loggedUser = useSelector((state) => state.login);
    const navigate = useNavigate();

    // MOBILE DETECTION (USED JUST FOR LOGO)
    const [mobileView, setMobileView] = useState(window.innerWidth);
    useEffect(() => {
        const handleResize = () => {
            setMobileView(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const goToAdd = () => {
        navigate('/add-edit-product');
    }

    // Handle requestAlert
    const products = useSelector((state) => state.login.products);
    useEffect(() => {
        if (products) {
            const requiredAmount = products.filter(element => element.saleStatus.status === 'Required').length;
            dispatch(setRequestsAmount(requiredAmount));
        }
    }, [products])
    const alerts = useSelector(state => state.login.requestsAmount);
    const { newConversationAlert } = useSelector(state => state.login);

    // controllo se l'utente è admin
    const { isAdmin } = useSelector(state => state.login);

    return (
        <div className="w-full border-b border-slate-700 bg-white">
            {/* Top navbar */}
            <div className="flex flex-row justify-between items-center p-4">
                <div className='flex gap-4 md:gap-8 w-full'>
                    <div className="flex gap-4 items-center text-slate-700 w-full">
                        {
                            loggedUser.isLogged ? (
                                <div className='flex justify-between w-full'>
                                    {/* Left (LOGO) */}
                                    <div>
                                        <Link to="/"><img src={logo} alt="logo" className='h-12' /></Link>
                                    </div>
                                    {/* Right */}
                                    <div className='flex items-center gap-4'>
                                        {
                                            isAdmin &&
                                            <Link to="/admin"><div className="pt-2.5 cursor-pointer"><i className="fi fi-rr-stats text-3xl"></i></div></Link>
                                        }
                                        {/* Messages */}
                                        <div className="relative">
                                            <Link to="/inbox"><i class="fi fi-rr-envelope text-slate-700 text-3xl mb-[-10px] hidden md:block"></i></Link>
                                            {newConversationAlert.length !== 0 && <div className="hidden md:flex w-4 h-4 rounded-lg bg-teal-500 justify-center items-center text-xs text-white absolute top-[-5px] right-[-5px]">{newConversationAlert.length}</div>}
                                        </div>
                                        {/* Preferiti */}
                                        <Link to="/favorite-products"><i class="fi fi-rr-star text-slate-700 text-3xl mb-[-10px] hidden md:block"></i></Link>
                                        {/* Nome e cognome */}
                                        <Link to="/my-profile"><div className='mb-[-5px] font-bold hidden md:block cursor-pointer'>{loggedUser.name} {loggedUser.surname}</div></Link>
                                        {/* Avatar */}
                                        <div className='flex flex-col items-center'>
                                            <Link to="/my-profile"><div className='hidden md:block'><UserAvatar url={loggedUser.avatarUrl} /></div></Link>
                                            {alerts !== 0 && <div className="hidden md:flex mt-[-10px] w-4 h-4 rounded-lg bg-teal-500 justify-center items-center text-xs text-white">{alerts}</div>}
                                        </div>
                                        {/* Supporto */}
                                        <div className='mt-2'><Link to="/support"><i className="fi fi-sr-cloud-question text-slate-500 text-3xl mr-2 md:mr-0"></i></Link></div>
                                        {/* Inserisci articolo Button */}
                                        <div className='hidden md:block'>
                                            <Link to="/add-edit-product"><PrimaryButton text="Inserisci articolo" /></Link>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div className='w-full flex gap-6 justify-between'>
                                    <Link to="/"><img src={logo} alt="logo" className='h-12' /></Link>
                                    <Link to="/login"><PrimaryButton text="Accedi o registrati" /></Link>
                                    {/* <Link to="/register"><SecondaryButton text="Registrati" /></Link> */}
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>
            {/* Bottom navbar */}
            <div className={`z-20 h-24 bg-slate-200 fixed bottom-0 left-0 right-0 md:hidden ${loggedUser.isLogged ? 'flex justify-around items-center' : 'hidden'}`}>
                <Link to="/"><div className='flex flex-col items-center' onClick={() => handleSelectPage(0)}><i className={`fi fi-rr-house-chimney ${selected === 0 ? 'text-teal-500' : 'text-slate-700'} text-2xl sm:text-3xl mb-[-10px]`}></i><span className='text-xs sm:text-sm text-slate-500 mt-1'>Home</span></div></Link>
                <Link to="/favorite-products"><div className='flex flex-col items-center' onClick={() => handleSelectPage(1)}><i className={`fi fi-rr-star ${selected === 1 ? 'text-teal-500' : 'text-slate-700'} text-2xl sm:text-3xl mb-[-10px]`}></i><span className='text-xs sm:text-sm text-slate-500 mt-1'>Preferiti</span></div></Link>
                <Link to="/add-edit-product"><div className='flex flex-col items-center' onClick={() => handleSelectPage(2)}><i className={`fi fi-rr-square-plus ${selected === 2 ? 'text-teal-500' : 'text-slate-700'} text-2xl sm:text-3xl mb-[-10px]`}></i><span className='text-xs sm:text-sm text-slate-500 mt-1'>Aggiungi</span></div></Link>
                <div className='relative'>
                    <Link to="/inbox"><div className='flex flex-col items-center' onClick={() => handleSelectPage(3)}><i className={`fi fi-rr-envelope ${selected === 3 ? 'text-teal-500' : 'text-slate-700'} text-2xl sm:text-3xl mb-[-10px]`}></i><span className='text-xs sm:text-sm text-slate-500 mt-1'>Messaggi</span></div></Link>
                    {newConversationAlert.length !== 0 && <div className="w-4 h-4 rounded-lg bg-teal-500 mt-[-10px] flex justify-center items-center text-xs text-white absolute top-1 right-2">{newConversationAlert.length}</div>}
                </div>
                <div className='flex flex-col items-center'>
                    <Link to="/my-profile"><div onClick={() => handleSelectPage(4)}><UserAvatar url={loggedUser.avatarUrl} /></div></Link>
                    {alerts !== 0 && <div className="w-4 h-4 rounded-lg bg-teal-500 mt-[-10px] flex justify-center items-center text-xs text-white">{alerts}</div>}
                </div>
            </div>

            {/* Side navbar */}
        </div>
    )
}

export default Navbar;