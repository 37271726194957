import 'chart.js/auto';
import { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import FetchLoader from '../FetchLoader';
import 'chartjs-adapter-date-fns';
import { useDispatch, useSelector } from 'react-redux';
import { getUsersMainData } from '../../redux/adminSlice';


// Questo grafico elabora i dati registrationData dello slice Admin
// --> Implementare il filtro temporale adattando l'arrivo di dati da backend (una chiamata ogni volta che l'admin cambia impostazioni)
const ChartCard = ({ registrationData }) => {
    const [chartData, setChartData] = useState({
        dates: [],
        counts: []
    })

    const dispatch = useDispatch();
    const [aggregation, setAggregation] = useState('month');

    // Effettuo una chiamata API ogni volta che cambia l'aggregazione
    useEffect(() => {
        dispatch(getUsersMainData({ groupBy: aggregation }))
    }, [aggregation])

    // Trasforma i dati ricevuti dal backend
    useEffect(() => {
        if (registrationData) {
            const dates = registrationData.map(element => {
                if (aggregation === 'week') {
                    // Converto "2023-44" in una data effettiva (primo giorno della settimana)
                    const [year, week] = element.period.split('-').map(Number);
                    const firstDayOfWeek = getFirstDayOfWeek(year, week);
                    return firstDayOfWeek.toISOString(); // Data in formato ISO
                }
                return element.period; // Giorno o mese non richiedono conversione
            });
            const counts = registrationData.map(element => element.count);
            setChartData({ dates, counts })
        }
    }, [registrationData])

    // Funzione per calcolare il primo giorno di una settimana
    const getFirstDayOfWeek = (year, week) => {
        const firstDayOfYear = new Date(Date.UTC(year, 0, 1)); // Primo giorno dell'anno
        const dayOffset = firstDayOfYear.getUTCDay(); // Giorno della settimana (0: domenica, 1: lunedì, ecc.)
        const firstWeekStart = new Date(firstDayOfYear);
        firstWeekStart.setUTCDate(firstDayOfYear.getUTCDate() + (1 - dayOffset)); // Sposta al primo lunedì dell'anno

        const weekStart = new Date(firstWeekStart);
        weekStart.setUTCDate(firstWeekStart.getUTCDate() + (week - 1) * 7); // Sposta all'inizio della settimana specificata
        return weekStart;
    };

    // Calcolo del range minimo e massimo per l'asse X
    const minDate = chartData.dates.length > 0 ? chartData.dates[0] : null;
    const maxDate = chartData.dates.length > 0 ? chartData.dates[chartData.dates.length - 1] : null;

    // Configurazione del grafico
    const data = {
        labels: chartData.dates,
        datasets: [
            {
                label: `Registrazioni ${aggregation === 'day' ? 'giornaliere' : aggregation === 'week' ? 'settimanali' : 'mensili'}`,
                data: chartData.counts,
                fill: false,
                backgroundColor: 'rgba(75,192,192,0.2)',
                borderColor: 'rgba(75,192,192,1)',
            }
        ]
    }

    // Opzioni dinamiche per l'asse delle X
    const options = {
        scales: {
            x: {
                type: 'time',
                time: {
                    unit: aggregation,
                    tooltipFormat: aggregation === 'day' ? 'yyyy-MM-dd' : aggregation === 'week' ? 'yyyy-MM' : 'yyyy-MM',
                    displayFormats: {
                        day: 'dd-MM-yyyy',
                        week: "ww, yyyy",
                        month: 'MM-yyyy'
                    }
                },
                title: {
                    display: true,
                    text: 'Periodo'
                },
                min: minDate, // Imposta la data minima
                max: maxDate  // Imposta la data massima
            },
            y: {
                beginAtZero: true,
                title: {
                    display: true,
                    text: 'Numero di registrazioni'
                }
            }
        },
        plugins: {
            legend: {
                display: true,
                position: 'top'
            },
            tooltip: {
                callbacks: {
                    label: (context) => {
                        return `${context.parsed.y} registrazioni`;
                    }
                }
            }
        }
    };

    return (
        <div className='w-full max-w-[1000px] xl:border rounded-xl p-8 flex flex-col items-center'>
            <h3>Registrazioni degli utenti dal {chartData.dates[0]}</h3>
            {/* Intervallo di aggregazione */}
            <div className="flex gap-8 my-4">
                <div className={`p-2 border rounded-lg cursor-pointer ${aggregation === 'day' && 'border-teal-400'}`} onClick={() => setAggregation('day')}>Al giorno</div>
                <div className={`p-2 border rounded-lg cursor-pointer ${aggregation === 'week' && 'border-teal-400'}`} onClick={() => setAggregation('week')}>Alla settimana</div>
                <div className={`p-2 border rounded-lg cursor-pointer ${aggregation === 'month' && 'border-teal-400'}`} onClick={() => setAggregation('month')}>Al mese</div>
            </div>
            <Line data={data} options={options} />
        </div>
    )
}

export default ChartCard;
