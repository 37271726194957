import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getProductsMainData, getUsersMainData } from '../redux/adminSlice';
import UserCard from '../components/adminComponents/UserCard.jsx';
import FetchLoader from '../components/FetchLoader.jsx';
import ChartCard from '../components/adminComponents/ChartCard.jsx';
import MainDataPanel from '../components/adminComponents/MainDataPanel';

const AdminPage = () => {

    const { usersData, productsData } = useSelector((state) => state.admin);
    const { usersMainData, registrationData } = usersData;

    // Get all users main data
    const { isAdmin } = useSelector(state => state.login);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    // Reindirizzo l'utente in homepage se non è admin
    if (isAdmin !== null) {
        if (!isAdmin) navigate("/")
    }
    useEffect(() => {
        dispatch(getUsersMainData({ groupBy: "month" }));
        dispatch(getProductsMainData());
    }, [isAdmin])

    return (
        <div className="main-container">
            <h2>Admin dashboard</h2>

            {/* Dati principali */}
            <MainDataPanel data={{ usersData, productsData }} />


            {/* Grafico registrazioni */}
            <ChartCard registrationData={registrationData} />


            {/* Elenco utenti */}
            {
                usersMainData?.data &&
                <div className="box w-full md:shadow-xl">
                    <div className='flex flex-col gap-2 w-full items-center'>
                        {usersMainData.count && <h2>Dati utenti</h2>}
                        <hr />
                        {
                            usersMainData.data.map((user, index) => (
                                <UserCard key={index} index={index} userData={user} isEven={index % 2 === 0 ? true : false} />
                            ))
                        }
                    </div>
                </div>
            }
        </div >
    )
}

export default AdminPage;