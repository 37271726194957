import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const getUsersMainData = createAsyncThunk(
    'users/getAllUsers',
    async ({groupBy}) => {
        const token = localStorage.getItem('tattooswap_auth_token');
        const url = `${process.env.REACT_APP_BASE_URL}/admin/users-data?groupBy=${groupBy}`;
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        });
        if (response.ok) {
            const usersData = await response.json();
            return usersData;
        } else {
            const errorResult = await response.json();
            console.error('Server error: ', errorResult)
            throw errorResult;
        }
    }
)

export const getProductsMainData = createAsyncThunk(
    'prodicts/getAllProducts',
    async () => {
        console.log(0)
        const token = localStorage.getItem('tattooswap_auth_token');
        const url = `${process.env.REACT_APP_BASE_URL}/admin/products-data`;
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        });
        if (response.ok) {
            const productsData = await response.json();
            console.log('productsData: ', productsData)
            return productsData;
        } else {
            const errorResult = await response.json();
            console.error('Server error: ', errorResult)
            throw errorResult;
        }
    }
)

const adminSlice = createSlice({
    name: 'admin',
    initialState: {
        usersData: {
            isLoading: false,
            error: null,
            usersMainData: null,
            registrationData: null
        },
        productsData: {
            isLoading: false,
            error: null,
            productsMainData: null,
            swaps: null
        }
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getUsersMainData.pending, (state) => {
                state.usersData.isLoading = true;
            })
            .addCase(getUsersMainData.fulfilled, (state, action) => {
                state.usersData.usersMainData = action.payload.payload.usersMainData; // Fallo arrivare paginato :)
                state.usersData.registrationData = action.payload.payload.registrationData;
                state.usersData.isLoading = false;
            })
            .addCase(getUsersMainData.rejected, (state, action) => {
                state.usersData.error = action.error;
                state.usersData.isLoading = false;
            })
            .addCase(getProductsMainData.pending, (state) => {
                state.productsData.isLoading = true;
            })
            .addCase(getProductsMainData.fulfilled, (state, action) => {
                state.productsData.productsMainData = action.payload.payload.productsMainData; // Fallo arrivare paginato :)
                state.productsData.swaps = action.payload.payload.swaps;
                state.productsData.isLoading = false;
            })
            .addCase(getProductsMainData.rejected, (state, action) => {
                state.productsData.error = action.error;
                state.productsData.isLoading = false;
            })
    }
})

export default adminSlice.reducer;