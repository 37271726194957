
const MainDataPanel = ({ data }) => {
    const { usersData, productsData } = data;
    const isUsersLoading = usersData?.isLoading;
    const isUsersError = usersData?.error;
    const isProductsLoading = productsData?.isLoading;
    const isProductsError = productsData?.error;
    return (
        <div className="box w-full">
            <h2 className="mb-8">Dati principali</h2>
            <div className="w-full flex justify-evenly">
                {/* Utenti totali Card */}
                <div className="w-[300px] flex flex-col border rounded-xl p-4">
                    <h5 className="text-slate-400">Utenti totali</h5>
                    <h1 className="text-teal-400">{usersData?.usersMainData?.count}</h1>
                    <div className="text-xs">(<span className="text-teal-400 font-bold">{usersData?.usersMainData?.geolocalizedProductsUsersCount}</span> hanno fornito la basePosition)</div>
                </div>
                {/* Prodotti totali Card */}
                <div className="w-[300px] flex flex-col border rounded-xl p-4">
                    <h5 className="text-slate-400">Prodotti totali</h5>
                    <h1 className="text-teal-400">{productsData?.productsMainData?.count}</h1>
                </div>
                {/* Scambi totali Card */}
                <div className="w-[300px] flex flex-col border rounded-xl p-4">
                    <h5 className="text-slate-400">Scambi totali</h5>
                    <h1 className="text-teal-400">{productsData?.swaps}</h1>
                </div>
            </div>
        </div>
    )
}

export default MainDataPanel